import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Layout } from "../components/themed";
import { BannerWrapper } from "../components/BannerWrapper";

const IndexPage = () => {
  return (
    <Layout>
      <BannerWrapper>
        <StaticImage
          src="../images/banners/banner.jpg"
          alt="Publicidad Aerea Banner"
        />
      </BannerWrapper>
      <h1 className="text-3xl font-bold">Publicidad Aerea</h1>
      <div className="h-[1000px]"></div>
      <h2>Hola</h2>
      <div className="h-[1000px]"></div>
      <h3>Publicidad aerea</h3>
      <div className="h-[1000px]"></div>
    </Layout>
  );
};

export default IndexPage;
